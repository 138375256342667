export const APP_NAME = "Reveal";
export const APP_TAG_LINE = "Wipe away stress and sooth your mind";
export const APP_ID_APPLE = "1585519202";
export const APP_ID_GOOGLE = "io.milesoft.scratch";

// export const API_BASE_URL = "http://localhost:8080";
export const API_BASE_URL = "https://reveal-app-j4g5e4jnfa-uc.a.run.app";
export const WEB_BASE_URL = "https://reveal.milesoft.app";

export const NAMESPACE = "reveal";
export const KEY_VERSION = "version";

export const PATH_DEEP_LINK = "/deep-link";
export const PAGE_SHARED = "/shared";
export const PAGE_HOME = "/";
