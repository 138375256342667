import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {CircularProgress, Divider, Typography} from "@mui/material";
import {
    AppStoreLinkApple,
    AppStoreLinkGoogle,
    CompanyLogo,
    ImageDialog, Tooltip,
    UserAvatar
} from "@atttomyx/react-components";
import {shareService} from "../../services";
import {router} from "@atttomyx/react-utils";
import {APP_ID_APPLE, APP_ID_GOOGLE, APP_NAME, APP_TAG_LINE, PAGE_HOME} from "../../constants";
import "./sharedImagePage.css";
import icon from "../../icon.png";

const MESSAGE_OPEN_IN_BROWSER =
    "If you clicked the link in another app (e.g. LinkedIn) you may need to choose the \"Open in Browser\" option to view the image.";

const SharedImagePage = (props) => {
    const {history, snackbar, match} = props;
    const [loading, setLoading] = useState(true);
    const [share, setShare] = useState(null);
    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
        const sharedId = match.params.id;

        if (sharedId) {
            const success = (share) => {
                if (share) {
                    setShare(share);

                } else {
                    snackbar.setWarning("Sorry, link expired");
                    router.redirectTo(history, PAGE_HOME);
                    // setShare({
                    //     player: {},
                    // });
                }

                setLoading(false);
            };

            const failure = (err) => {
                snackbar.setError(err);
                setLoading(false);
            };

            shareService.loadShare(sharedId, success, failure);
        }
    }, []);

    return (
        <div className="shared-image-page">
            {loading ?
                <CircularProgress size={80} color="primary"/> : share ?
                <>
                    <div className="wrapper" onClick={() => setShowImage(true)}>
                        <UserAvatar
                            user={share.player}
                            size="large"
                            mode="no-title"
                        />
                        <Typography className="username" variant="caption">
                            {share.player.alias}
                        </Typography>
                    </div>
                    <Typography variant="h5" className="shared">
                        Shared an image with you!
                    </Typography>
                    <Typography variant="body2">
                        To view it, download <strong>{APP_NAME}</strong> and
                    </Typography>
                    <Typography variant="body2">
                        click <strong>{share.player.alias}'s link</strong> again.
                        <span className="tooltip"><Tooltip message={MESSAGE_OPEN_IN_BROWSER}/></span>
                    </Typography>
                    <Divider/>
                    <AppStoreLinkApple
                        appId={APP_ID_APPLE}
                    />
                    <AppStoreLinkGoogle
                        appId={APP_ID_GOOGLE}
                    />
                    <Divider/>
                    <CompanyLogo mode="logo" size="medium" className="clickable"/>
                    <table className="app" cellPadding="0" cellSpacing="10px">
                        <tbody>
                        <tr>
                            <td>
                                <img alt={APP_NAME} src={icon} className="icon"/>
                            </td>
                            <td>
                                <Typography variant="h5">
                                    {APP_NAME}
                                </Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <Typography variant="body1">
                        {APP_TAG_LINE}
                    </Typography>
                    {showImage ?
                        <ImageDialog
                            title={share.player.alias}
                            imageUrl={share.player.imageUrl}
                            onCancel={() => setShowImage(false)}
                        /> : null}
                </> : null}
        </div>
    )
};

export default withRouter(SharedImagePage);
